<template>
  <v-container class="ma-0" fluid>
    <template v-if="isShow && isFinal">
      <FinalReviewContract
        :docs="attentedDocs"
        :onBackClicked="onBackClicked"
      />
    </template>
    <template v-else-if="isShow && !isFinal">
      <UploadRequiredDocuments
        :docs="docs"
        :onFileUploaded="onFileUploaded"
        :remove="remove"
        :goNextPageConfirm="goNextPageConfirm"
        :goNextPage="goNextPage"
        :showUploadErrorDialog="showUploadErrorDialog"
        :uploadErrorMessage="uploadErrorMessage"
        :showConfirmDialog="showConfirmDialog"
        :closeUploadErrorDialog="closeUploadErrorDialog"
        :closeConfirmDialog="closeConfirmDialog"
      />
    </template>
  </v-container>
</template>

<script>
import FinalReviewContract from '@/pages/contractor/checkNeedUpload/FinalReviewContract';
import UploadRequiredDocuments from '@/pages/contractor/checkNeedUpload/UploadRequiredDocuments';
import { mapActions, mapGetters } from 'vuex';
import {
  uploadLimitFileSize,
  ExtensionsAllowedInRequiredFile,
  MaxLength,
} from '@/lib/const';

export default {
  props: {
    stepPoint: Number,
    attentedDocs: Array,
  },
  components: {
    FinalReviewContract,
    UploadRequiredDocuments,
  },
  data() {
    return {
      docs: [],
      showUploadErrorDialog: false,
      showConfirmDialog: false,
      uploadErrorMessage: null,
      isFinal: true,
      isShow: false,
    };
  },
  methods: {
    ...mapGetters('contract', ['pendingStep', 'needUpload']),
    ...mapActions('contract', ['setPendingStep']),
    onFileUploaded: function(e) {
      const files = e.target.files || e.dataTransfer.files;
      const reader = new FileReader();
      reader.onload = v => {
        // ファイル名が最大文字数を超える場合
        if (files[0].name.length > MaxLength.FileName) {
          this.$set(
            this,
            'uploadErrorMessage',
            this.$t('validation.maxFileName', {
              length: MaxLength.FileName,
            })
          );
          this.$set(this, 'showUploadErrorDialog', true);
          e.target.value = '';

          return;
        }
        if (files[0].size <= uploadLimitFileSize) {
          const contentType = v.target.result
            .toString()
            .slice(
              v.target.result.indexOf(':') + 1,
              v.target.result.indexOf(';')
            );
          // ファイルの拡張子チェック
          if (ExtensionsAllowedInRequiredFile.includes(contentType)) {
            // ファイル名の重複チェック
            this.docs.some(doc => {
              if (doc.name === files[0].name) {
                this.$set(
                  this,
                  'uploadErrorMessage',
                  this.$t('error.sameFileNameExists', {
                    fileName: files[0].name,
                  })
                );
                this.$set(this, 'showUploadErrorDialog', true);
                e.target.value = '';
                return true;
              }
            });
            this.docs.push({
              name: files[0].name,
              file: v.target.result,
              fileData: files[0],
              contentType: contentType,
            });
          } else {
            let extension = files[0].name.slice(
              ((files[0].name.lastIndexOf('.') - 1) >>> 0) + 2
            );
            this.$set(
              this,
              'uploadErrorMessage',
              this.$t('error.uploadRequiredDocumentsNotEligible', {
                extension: extension,
              })
            );
            this.$set(this, 'showUploadErrorDialog', true);
          }
        } else {
          this.$set(
            this,
            'uploadErrorMessage',
            this.$t('error.uploadRequiredDocumentsLimitFileSizeOver')
          );
          this.$set(this, 'showUploadErrorDialog', true);
        }
        e.target.value = '';
      };
      reader.readAsDataURL(files[0]);
    },
    remove: function(v) {
      this.docs = this.docs.filter(k => k != v);
    },
    goNextPageConfirm: function() {
      if (this.docs.length > 0) {
        this.goNextPage();
      } else {
        this.$set(this, 'showConfirmDialog', true);
      }
    },
    goNextPage: function() {
      this.$set(this, 'showConfirmDialog', false);
      const nextStep = this.stepPoint + 1;
      if (this.pendingStep() < nextStep) this.setPendingStep(nextStep);

      window.scrollTo(0, 0);

      this.$router.push({
        name: 'ExplanImportantMatters',
        params: { docs: this.docs },
      });
    },
    onBackClicked() {
      this.$router.push({
        name: 'ExplanImportantMatters',
        params: { docs: this.docs },
      });
    },
    closeUploadErrorDialog() {
      this.$set(this, 'showUploadErrorDialog', false);
    },
    closeConfirmDialog() {
      this.$set(this, 'showConfirmDialog', false);
    },
  },
  mounted() {
    if (this.$route.hash) {
      switch (this.$route.hash) {
        case '#final_review_contract':
          this.$set(this, 'isFinal', true);
          break;
        case '#upload_required_documents':
          if (!this.needUpload())
            this.$router.push({
              hash: '#final_review_contract',
            });
          this.$set(this, 'isFinal', false);
          break;
        default:
          this.$set(this, 'isFinal', true);
      }
    }
    this.$set(this, 'isShow', true);
  },
};
</script>
